<template>
  <div class="box">
    <div class="title">修改密码</div>
    <el-form
      :model="PassForm"
      :rules="rules"
      ref="PassForm"
      label-width="100px"
      class="commonform"
    >
      <el-form-item label="原密码" prop="oldPass">
        <el-col :span="8">
          <el-input
            v-model="PassForm.oldPass"
            type="password"
            placeholder="请输入原密码"
            maxlength="20"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="新密码" prop="newPass">
        <el-col :span="8">
          <el-input
            v-model="PassForm.newPass"
            type="password"
            placeholder="请设置新密码"
            maxlength="20"
          ></el-input
        ></el-col>
      </el-form-item>
      <el-form-item label="确认新密码" prop="confirmPass">
        <el-col :span="8">
          <el-input
            v-model="PassForm.confirmPass"
            type="password"
            placeholder="请再次输入新密码"
            maxlength="20"
          ></el-input
        ></el-col>
      </el-form-item>
      <el-button
        type="primary"
        @click="onSubmit('PassForm')"
        :loading="passLoading"
        class="btn"
        >保 存</el-button>
    </el-form>
  </div>
</template>

<script>
import LoginApi from "@/api/LoginApi";
export default {
  name: "ModifyUserPassPage",
  components: {},
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.PassForm.newPass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      passLoading: false,
      PassForm: {
        oldPass: "",
        newPass: "",
        confirmPass: "",
        userId: "",
      },
      rules: {
        oldPass: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        newPass: [{ required: true, message: "请设置新密码", trigger: "blur" }],
        confirmPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = {};
          this.passLoading = true;
          let _data = Object.assign({}, this.PassForm);
          res = await LoginApi.modifyPass(_data);
          this.passLoading = false;
          if (res.code === "200") {
            this.$message({
              type: "success",
              message: res.msg,
            });
          }else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.title{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.btn{
  margin-left: 20%;
}
.commonform{
  width: 40%;
  margin-left: 40%;
  margin-top: 50px;
}
</style>
